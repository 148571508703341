export const entries = [
    {
        id:  '12',
        title: 'Q - 12',
        description: 'Schließe diese eine Tür und beginne eine neue zu öffnen und schon geht es dir besser.',
    },
    {
        id:  '11',
        title: 'Q - 11',
        description: 'Such nicht nach all den Gründen, warum etwas nicht funktioniert. Finde nur einen Grund, warum es funktionieren könnte!',
    },
    {
        id:  '10',
        title: 'Q - 10',
        description: 'Die glücklichsten Menschen haben nicht von allem das Beste. Sie machen aus allem das Beste!',
    },
    {
        id:  '9',
        title: 'Q - 9',
        description: 'Krisen sind Angebote des Lebens, sich zu wandeln. Man braucht noch gar nicht zu wissen, was neu werden soll. Man muss nur bereit und zuversichtlich sein.',
    },
    {
        id:  '8',
        title: 'Q - 8',
        description: 'Am Ende wird alles gut werden, und wenn es noch nicht gut ist, dann ist es noch nicht am Ende.',
    },
    {
        id:  '7',
        title: 'Q - 7',
        description: 'Es gibt Berge, über die man hinüber muss, sonst geht der Weg nicht weiter.',
    },
    {
        id:  '6',
        title: 'Q - 6',
        description: 'Der Optimist irrt genauso oft, wie der Pessimist, aber er hat viel mehr Spaß dabei.',
    },
    {
        id:  '5',
        title: 'Q - 5',
        description: 'Wenn du glaubst es geht nicht mehr, kommt von irgendwo ein Lichtlein her.',
    },
    {
        id:  '4',
        title: 'Q - 4',
        description: 'Jede schwierige Situation, die du jetzt meisterst, bleibt dir in der Zukunft erspart.',
    },
    {
        id:  '3',
        title: 'Q - 3',
        description: 'Hinfallen. Aufstehen. Krone richten. Weitergehen.',
    },
    {
        id:  '2',
        title: 'Q - 2',
        description: 'Alles ist schwierig, bevor es einfach wird.',
    },
    {
        id:  '1',
        title: 'Q - 1',
        description: 'Lächle am Abend, denn der Morgen bringt dir eine neue Chance, dass alles wieder gut wird.',
    },
    {
        id:  '0',
        title: 'Q - Frei',
        description: 'Jeder Tag ist eine neue Chance für Dein Glück.',
    },
]