import {
  IonButton,
  IonModal,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonList,
  IonItem,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonCard
} from '@ionic/react';
import { count } from 'console';
import React, { useEffect, useState } from 'react';
//import { Entry } from '../models'
import { entries } from '../data';
import './Qualendar.css';





const Qualendar: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState("");
  const [counter, setCounter] = useState(0);
  const [today, setToday] = useState(new Date().getDate());
  const [qDay, setQDay] = useState(29)


  // const[entries, setEntries] = useState<Entry[]>([]);

  // useIonViewDidEnter(() => {
  //   console.log('ionViewDidEnter event fired');

  // });

  // useIonViewDidLeave(() => {
  //   console.log('ionViewDidLeave event fired');
  // });

  // useIonViewWillEnter(() => {
  //   console.log('ionViewWillEnter event fired');

  // });

  // useIonViewWillLeave(() => {
  //   console.log('ionViewWillLeave event fired');
  // });

  const openDoor = () => {
    console.log("openDoor");
    setCounter(counter + 1);
    setText("test: " + counter);
    setShowModal(true);
  }

  const openDoor2 = (myText: string, entryNum: string) => {
    //console.log("today: " + today);
    // setCounter(counter + 1);
    //setText("test: " + counter);
    // setText(myText + " " + counter)
    
    setText(myText)
    
    if(qDay > today) {
      var diff = qDay - today;
      if(parseInt(entryNum) < diff) {
        setText("Die Türe ist noch nicht freigeschaltet ... probiere es morgen nochmal!")
      }
    }
    
    setShowModal(true);
  }


  return (
    <div className="container">

      {/* { <IonList>
          { entries.map((entry) =>
            <IonItem key={entry.id}>
              { entry.title }
              </IonItem>
          )}
        </IonList> } */}

        { entries.map((entry) =>
            // <div className="Door" key={entry.id} onClick={() => openDoor()}>
            <div className="Door" key={entry.id} onClick={() => openDoor2(entry.description, entry.id)}>
              { entry.title }
            </div>
        )}

      <IonModal isOpen={showModal}>
          
        <IonCard>
            <IonCardHeader>
              {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
              <img src="/assets/images/cardThumbnail.jpg" />
              <IonCardTitle>Mutmacher Spruch des Tages</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              { text }
            </IonCardContent>
        </IonCard>
          
        <IonButton onClick={() => setShowModal(false)} expand="block" shape="round" fill="outline">
            Toller Spruch - schließen!
        </IonButton>
      </IonModal>
      {/* <IonButton onClick={() => setShowModal(true)}>
            Show Modal
      </IonButton> */}

    </div>
  );
};

export default Qualendar;
